import React, { Component } from "react";
import "./Services.css";
class Services extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <div>Services</div>;
  }
}
export default Services;
