import React, { Component } from "react";
import "./AboutUs.css";
import Breadcrumb from "../../Breadcrumb/Breadcrumb";
import "bootstrap/dist/css/bootstrap.min.css";
import aboutImage from "./images/bag2_imgaboutus.png";
class AboutUs extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Breadcrumb Page="AboutUs" />

        {/* <!-- About Start --> */}
        <div className="container-fluid py-5">
          <div className="container">
            <div className="section-title">
              <h4 className="text-primary text-uppercase">About Us</h4>
              <h1 className="display-4">
                MAZACONE GREEN SOLUTIONS PAPER PRODUCTS
              </h1>
              <p>
                is a name associated with PRINTING AND PACKAGING INDUSTRY,
                manufacturing &amp; distributing wide range of Paper &amp; cloth
                packing products. We ventured into this industry with a vision
                of revolutionizing the traditional definition of printing &amp;
                packaging.
              </p>
              <p>
                We cater to small and large customers with attentive
                personalized service. We can work with ideas using computer
                aided design technology to bring you from concept to production.
                Our goal is to consistently exceed customer expectations with
                environment friendly professional service, partner with you to
                reach your goals and earn your loyalty.
              </p>
            </div>
            <div className="row">
              <div className="col-lg-4 py-0 py-lg-5">
                <h1 className="mb-3">Quality</h1>
                <h5 className="mb-3">
                  From the initial design stage through to the final delivery
                  utmost care is taken to manufacture quality product.
                </h5>
                <p>
                  We know that the key to success is in making a perfect
                  product. At early stage from the analysis of raw material,
                  in-house and post-process inspection, our commitment to rigid
                  environmental and quality standards is unfailing.
                </p>
              </div>
              <div className="col-lg-4 py-5 py-lg-0 mheight">
                <div className="position-relative h-100">
                  <img
                    className="position-absolute rounded aboutimg1"
                    src={aboutImage}
                    // style={{ objectFit: "cover" }}
                  />
                </div>
              </div>
              <div className="col-lg-4 py-0 py-lg-5">
                <h1 className="mb-3">Mission</h1>
                <p>
                  To provide complete printing and packaging solution as an
                  end-to-end solutions provider using our experience and
                  expertise to achieve your goals for quality, value and
                  production efficiencies in line with your budget and delivered
                  to your door on time.
                </p>
                {/* <h5 className="mb-3">
                  <i className="fa fa-check text-primary mr-3"></i>Lorem ipsum
                  dolor sit amet
                </h5>
                <h5 className="mb-3">
                  <i className="fa fa-check text-primary mr-3"></i>Lorem ipsum
                  dolor sit amet
                </h5>
                <h5 className="mb-3">
                  <i className="fa fa-check text-primary mr-3"></i>Lorem ipsum
                  dolor sit amet
                </h5> */}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- About End --> */}
        <div className="map-cont">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3926.832148974422!2d76.38552721488024!3d10.194282792718125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b0807b07195e6e1%3A0x9ad8500a67b6db7a!2sMAZACONE%20PAPER%20PRODUCTs!5e0!3m2!1sen!2sin!4v1665770074839!5m2!1sen!2sin"
            width="100%"
            height="400"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    );
  }
}
export default AboutUs;
